import { TextInlineLink } from '../../../../../components/cms/wiki_functions';
import { Appearance } from "../../../../../components/appearance";

export function Cyrenaic() {
    return {
        "date": <span>~4900 <TextInlineLink name={"bs_timeline"} text={"BT"} /></span>,
        "owned": <span><TextInlineLink name={"bs_state_cydonian_priority"} /></span>,
        "habitats": <span>18 billion</span>,
        "people": <span><span><TextInlineLink name={"bs_people_cydonians"} />, <TextInlineLink name={"bs_people_alamarsie"} /> (<TextInlineLink name={"bs_spec_humans"}
            text={"Homo sapiens"} />)</span></span>,
        "moons": <span>6 (Ialos, and others)</span>,
        "g": <span>1.01 terrestrial</span>,
        "atmosphere": <span className="text-success">suitable for humans</span>,
        "biosphere": <span>native, suitable for humans</span>,
        "system": <span>Naïades</span>,
        "sector": <span><TextInlineLink name={"bs_space_mistral_sector"} text={"Mistral"} /></span>,
        "type": <span>semi-metropolis</span>,
        "region": <span><TextInlineLink name={"bs_space_brienne_cluster"} /></span>,
        "area": <span><TextInlineLink name={"bs_space_arthuri_belt"} /></span>,
        "imgs": [
        ],
        "appears":
            [
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_autumn"} />
                </div>
            ],
        "text":
            <>
                <div className="row">
                    <div className="col-lg-12">
                        <h3 className="title-bg">Brief</h3>
                        <p className='card-text text-justify'>
                            <span className='strong'>Cyrenaic</span> - is a mysterious and resource-rich planet located in the Arthuri Belt of the Mistral sector. It holds immense cultural significance for the Alamarsie as the birthplace of Arthurianism. This world was once a rare stop for their nomadic fleets, serving as an orbiting hub for trade and cultural exchange.
                        </p>
                        <p className='card-text text-justify'>
                            Legend has it that Arthur Caliburn’s unification of the Alamarsie fleets began here, following a momentous council aboard one of their great hive ships. Over millennia, Cyrenaic became steeped in mythology, with the region around its orbit linked to stories of the Steel Hydra and other cosmic creatures. Its name resonates in Alamarsie history as a reminder of their spiritual and communal roots, even for those who never descend to its surface.
                        </p>
                        <h3 className="title-bg">Confluence of Past and Present</h3>
                        <p className='card-text text-justify'>
                            Cyrenaic, a planet of stark contrasts and layered histories, orbits at the edge of the Arthuri Belt in the Mistral sector. Though its surface is now home to a thriving Cydonian population, it remains one of the few places in the galaxy where the nomadic Alamarsie and settled “planetwalkers” coexist peacefully. This fragile harmony has made Cyrenaic a symbol of cultural convergence and spiritual continuity, its orbit a nexus of faith, trade, and diplomacy.
                        </p>
                        <h3 className="title-bg">Divided Surface</h3>
                        <p className='card-text text-justify'>
                            The planet itself is marked by striking geography: sprawling deserts of red crystal sands, interspersed with lush oases that make its colonies viable. The Cydonian settlers have built compact, self-sufficient cities powered by geothermal energy, blending technological sophistication with architectural austerity. Their settlements are strategically located near ancient Alamarsie landing zones, some of which have been converted into “sky chapels” maintained by traveling Alamarsie priests.
                        </p>
                        <p className='card-text text-justify'>
                            Cydonians, known for their pragmatism and technological ingenuity, have largely welcomed the Alamarsie’s continued presence, seeing their unique culture and philosophies as an asset rather than a threat. The nomads, in turn, regard Cyrenaic as a sacred place—a world that once served as the meeting ground for their ancestors and as the spiritual home of Arthurianism.
                        </p>
                        <h3 className="title-bg">Alamarsie Orbit</h3>
                        <p className='card-text text-justify'>
                            Above the surface, Cyrenaic’s orbit teems with activity. Hive ships, ancient and massive, drift near the planet like floating cathedrals, their hulls adorned with vibrant murals depicting the Steel Hydra, Hartrhon, and other Arthurian legends. These vessels act as mobile “space churches” for the Alamarsie, hosting sermons, councils, and ceremonial readings of Captain Arthur Caliburn’s diaries. Some ships serve as communal hubs where both Alamarsie and Cydonians engage in trade, debate philosophy, and share stories.
                        </p>
                        <p className='card-text text-justify'>
                            While the Alamarsie maintain their nomadic traditions, Cyrenaic’s orbit offers them a rare stability—a place to repair their vessels, resupply, and reconnect with their heritage. For some nomads, it’s the closest thing to a home they’ve ever known.
                        </p>
                        <h3 className="title-bg">Harmony Amid Tensions</h3>
                        <p className='card-text text-justify'>
                            Despite the peace, tensions simmer beneath the surface. Some Cydonians view the Alamarsie as relics of a bygone era, their presence tolerated more than embraced. Conversely, a few Alamarsie factions accuse the Cydonians of desecrating Cyrenaic’s sanctity by imposing their urban sprawl on a world steeped in their mythology.
                        </p>
                        <p className='card-text text-justify'>
                            Yet, Cyrenaic’s unique role as a bridge between the nomads and planetwalkers fosters a rare spirit of collaboration. Joint festivals celebrating Arthurian legends draw both populations together, and debates about Caliburn’s teachings often spark intellectual exchanges that strengthen their tenuous bond.
                        </p>
                        <p className='card-text text-justify'>
                            Cyrenaic is more than a planet—it’s a living symbol of humanity’s capacity for coexistence amid diversity. Here, the echoes of Arthur Caliburn’s vision resonate in every sky chapel and hive ship, reminding both the Cydonians and Alamarsie of their shared past, even as they chart divergent futures. For travelers across the galaxy, Cyrenaic stands as a rare testament to peace, a beacon for those seeking wisdom and unity in an ever-fractured universe.
                        </p>

                        <h3 className="title-bg">Notable Sky Churches of Cyrenaic</h3>
                        <h4 className="title-bg">The Skyward Ark</h4>
                        <p className='card-text text-justify'>
                            <span className='strong'>Location</span>: High orbit above Cyrenaic
                        </p>
                        <p className='card-text text-justify'>
                            <span className='strong'>Description</span>: The Skyward Ark is one of the largest hive ships still in operation, its towering hull painted with scenes from Captain Arthur Caliburn’s diaries. The Steel Hydra, Hartrhon, and other Arthurian creatures are depicted in a style blending Alamarsie iconography with the sleek, minimalist design favored by Cydonians.
                        </p>
                        <p className='card-text text-justify'>
                            <span className='strong'>Purpose</span>: The Ark serves as a roving council chamber for Arthurian elders. It hosts the Council of Stars, an annual gathering where disputes are settled, religious doctrines are debated, and new interpretations of Caliburn’s writings are shared.
                        </p>
                        <p className='card-text text-justify'>
                            <span className='strong'>Significance</span>: The Ark symbolizes unity and the pursuit of knowledge, with its motto inscribed in Alamarsie script: “By the stars, we are guided; by the Hydra, we are tested.”
                        </p>


                        <h4 className="title-bg">The Red Chapel of Venera’s Hollow</h4>
                        <p className='card-text text-justify'>
                            <span className='strong'>Location</span>: A canyon oasis in Cyrenaic’s southern desert
                        </p>
                        <p className='card-text text-justify'>
                            <span className='strong'>Description</span>: Nestled in the red crystal sands, this surface church is a marvel of reflective architecture. Its crimson walls are embedded with crystals that refract sunlight into kaleidoscopic patterns, creating an ethereal atmosphere during worship.
                        </p>
                        <p className='card-text text-justify'>
                            <span className='strong'>Purpose</span>: A pilgrimage site for both Alamarsie and Cydonians, the Red Chapel is dedicated to introspection and spiritual renewal.
                        </p>
                        <p className='card-text text-justify'>
                            <span className='strong'>Rituals</span>: The chapel holds a semi-annual Call of the Void ceremony, where participants reflect on their place in the universe while gazing into the endless desert, symbolizing the vastness of space
                        </p>
                        <p className='card-text text-justify'>
                            <span className='strong'>Significance</span>: It serves as a reminder of humanity’s smallness in the cosmos and the need for humility and wonder.”
                        </p>


                        <h4 className="title-bg">The Voidspire Cathedral</h4>
                        <p className='card-text text-justify'>
                            <span className='strong'>Location</span>: Geostationary orbit above Cyrenaic’s largest Cydonian city
                        </p>
                        <p className='card-text text-justify'>
                            <span className='strong'>Description</span>: A towering structure that combines the Alamarsie hive ship aesthetic with Cydonian nanotech. Its central spire extends into space, visible from the planet’s surface as a shimmering beacon.
                        </p>
                        <p className='card-text text-justify'>
                            <span className='strong'>Purpose</span>: A center for interfaith dialogue, the Voidspire welcomes both Alamarsie nomads and Cydonian citizens.
                        </p>
                        <p className='card-text text-justify'>
                            <span className='strong'>Rituals</span>: The Light of Origins ceremony, held once a year, projects holographic constellations of the Arthuri Belt while elders read passages from Caliburn’s diaries, connecting worshippers to their shared heritage.
                        </p>
                        <p className='card-text text-justify'>
                            <span className='strong'>Significance</span>: The Voidspire is a symbol of reconciliation and collaboration, a place where philosophical and cultural exchanges flourish.
                        </p>


                        <h4 className="title-bg">The Haven of Caliburn’s Rest</h4>
                        <p className='card-text text-justify'>
                            <span className='strong'>Location</span>:  A small moon orbiting Cyrenaic, known as Ialos
                        </p>
                        <p className='card-text text-justify'>
                            <span className='strong'>Description</span>: This serene sanctuary is said to be where Captain Arthur Caliburn spent his final days, according to Arthurian tradition. It is a modest structure surrounded by sprawling gardens that bloom year-round, thanks to advanced terraforming.
                        </p>
                        <p className='card-text text-justify'>
                            <span className='strong'>Purpose</span>: A retreat for those seeking solitude and reflection. Pilgrims come here to meditate and read Caliburn’s original diaries, preserved under holographic glass.
                        </p>
                        <p className='card-text text-justify'>
                            <span className='strong'>Significance</span>: It represents the intersection of legend and history, where believers can feel closest to their spiritual founder.
                        </p>


                        <h4 className="title-bg">The Lantern of the Hydra</h4>
                        <p className='card-text text-justify'>
                            <span className='strong'>Location</span>: A remote orbiting station near Cyrenaic’s outer atmosphere
                        </p>
                        <p className='card-text text-justify'>
                            <span className='strong'>Description</span>: A stark, metallic structure surrounded by a cloud of glittering solar panels, the Lantern glows faintly in the night sky. Inside, its halls are filled with murals depicting Arthurian cosmic beasts like the Steel Hydra and Cyllopus.
                        </p>
                        <p className='card-text text-justify'>
                            <span className='strong'>Purpose</span>: The Lantern is a center for mythological study, attracting scholars and artists who reinterpret Arthurian beasts in modern contexts.
                        </p>
                        <p className='card-text text-justify'>
                            <span className='strong'>Rituals</span>: The Hydra’s Dance, a choreographed light show, takes place annually, reenacting Caliburn’s legendary “encounter” with the Steel Hydra through projected visuals.
                        </p>
                        <p className='card-text text-justify'>
                            <span className='strong'>Significance</span>: This church represents the creative and interpretive aspects of Arthurianism, bridging ancient myths with contemporary culture.
                        </p>
                    </div>
                </div>
            </>
    }
}
