import { TextInlineLink } from '../../../../../components/cms/wiki_functions';
import imgs from '../../../../../components/cms/images_repo.json';

export function Religion_arthurians() {
    return {
        "top": <div className="row">
            <div className="col-lg-6">
                <ul>
                    <li>
                        <i className="fas fa-chevron-right"></i>
                        <strong>Type: </strong> <span>religion</span>
                    </li>
                    <li>
                        <i className="fas fa-chevron-right"></i> <strong>Founder: </strong>
                        <span><TextInlineLink name={"bs_char_arthur_the_founder"} /></span>
                    </li>
                </ul>
            </div>
            <div className="col-lg-6">
                <ul>
                    <li>
                        <i className="fas fa-chevron-right"></i> <strong>Pantheon:</strong>
                        <span><TextInlineLink name={"bs_creature_hydra"} />, <TextInlineLink name={"bs_creature_hartrhon"} />, <TextInlineLink name={"bs_creature_cyllopus"} /></span>
                    </li>
                    <li>
                        <i className="fas fa-chevron-right"></i> <strong>Sacred places: </strong>
                        <span><TextInlineLink name={"bs_space_arthuri_belt"} />, <TextInlineLink name={"bs_planet_cyrenaic"} /></span>
                    </li>
                </ul>
            </div>
        </div>,
        "text":
            <>
                <h3 className="title-bg">History</h3>
                <div style={{ "maxWidth": "220px", "padding": "0.25rem", marginRight: "0.5em", "backgroundColor": "#fff", "border": "1px solid #dee2e6", "float": "left" }}>
                    <img style={{ "maxWidth": "200px" }} alt="Arthurianism" src={imgs["bs_religion_arthur_1"].link} fetchpriority="low" />
                </div>
                <p className="card-text text-justify">
                    <span className="strong">Arturianism</span> is an ancient cult of the Alamarsie, the first doctrine in history, which originated after the settlement of humans in the galaxy. It is a philosophical and moral system, built around the diaries of semi-mythical Captain Arthur Caliburn and his syncretic views of the universe. They contain both strictly scientific theories and outright fantastical remarks about the existence of giant space monsters. <TextInlineLink name={"bs_creature_hydra"} />, <TextInlineLink name={"bs_creature_hartrhon"} />, <TextInlineLink name={"bs_creature_cyllopus"} />, and other creatures from <TextInlineLink name={"bs_people_alamarsie"} text={"Alamarsie"} /> children's tales are reinterpreted by Arthur and elevated to the rank of real things.
                </p>
                <p className="card-text text-justify">
                    Arthur's diaries are stored on different <TextInlineLink name={"bs_tech_hiveship"} text={"hiveships"} />, periodically moved, separated, and reassembled in one place. When and how this happens, only a few know, and none of them will allow outsiders to see the sacred pages. Throughout history, only a few scholars have been able to work with the texts, but the impossibility of verifying the conclusions negates their value.
                </p>
                <div style={{ "maxWidth": "220px", "padding": "0.25rem", marginLeft: "0.5em", "backgroundColor": "#fff", "border": "1px solid #dee2e6", "float": "right" }}>
                    <img style={{ "maxWidth": "200px" }} alt="Arthurianism" src={imgs["bs_religion_arthur_2"].link} fetchpriority="low" />
                </div>
                <p className="card-text text-justify">
                    In spite of this, the reality of Arthur as a historical figure is not to be doubted. Facts from independent sources prove that several millennia ago there really was a captain who rallied the Alamarsie around himself. Partially surviving chronicles of ancient planetary settlements point to the mysterious leader of the nomads, who managed to unite the fragmented and often conflicting fleets into a single armada. If the governors of the first Earth colonies are to be believed, this man spoke for all nomads, which seemed an incredible achievement: in those days, different fleets of Alamarsie could not get along in the same territory.
                </p>
                <p className="card-text text-justify">
                    Another proof of Arthur's reality can be considered excerpts from the diaries of his Alamarsie contemporaries: scientists have repeatedly found in the wreckage of nomadic fleets manuscripts dating to a similar era. There, descriptions of Arthur and his activities coincide with everything the inhabitants of the planets said about him. According to these documents, he was one of the creators of the extraterrestrial civilization of Homo sapiens: he developed a sophisticated system of <TextInlineLink name={"bs_tech_abspace"} text={"Overspace"} /> beacons (not yet the InterNetwork) that guided lost ships to the bases of the first Alamarsie (a breakthrough for those times) and laid the foundation for the social structure of hiveships. For this the nomads nicknamed him the Founder.
                </p>
                <div style={{ "maxWidth": "220px", "padding": "0.25rem", marginRight: "0.5em", "backgroundColor": "#fff", "border": "1px solid #dee2e6", "float": "left" }}>
                    <img style={{ "maxWidth": "200px" }} alt="Arthurianism" src={imgs["bs_religion_arthur_3"].link} fetchpriority="low" />
                </div>
                <p className="card-text text-justify">
                    Arthur's goals remained unclear: combining so many ships into one fleet meant the need to multiply resource extraction and a dramatic increase in logistical problems. Even one Alamarsie fleet is a very large formation of thousands of ships of all shapes and sizes. Bringing dozens of such fleets together forms an armada that no admiral could handle, and the practical benefits of doing so are almost nil. Some scholars believe that this was Arthur's way of trying to defend himself from pirates, but in those days the very notion of piracy was very different from today. Fuel was very expensive, speeds were an orders of magnitude lower, and uninhabited spaces occupied almost the entire galaxy. Therefore, it is unlikely that there were robbers willing to chase after a shabby nomadic fleet that outnumbered any pirate armada, but lacked the resources for which it was worth risking your life in those days. Even today, thousands of years later, when the Alamarsie hive has come to be regarded as prized booty, very few bandits dare to engage in conflict with such a huge fleet of even semi-civilian ships.
                </p>
                <p className="card-text text-justify">
                    Another theory is more likely correct: Arthur deliberately tried to create a new doctrine, so he tried to influence as many people as possible.
                </p>
                <div style={{ "maxWidth": "220px", "padding": "0.25rem", marginLeft: "0.5em", "backgroundColor": "#fff", "border": "1px solid #dee2e6", "float": "right" }}>
                    <img style={{ "maxWidth": "200px" }} alt="Arthurianism" src={imgs["bs_religion_arthur_4"].link} fetchpriority="low" />
                </div>
                <p className="card-text text-justify">
                    Apart from his philosophical teachings, Arthur left almost nothing behind: the inventions attributed to him have long since disappeared into the void of space, the Alamarsie have lost their unity, and the cult does not prescribe any commandments or standards of behavior. Therefore, many Alamarsie are simultaneously followers of Arthur and yet affiliate themselves with some other religion, of which there are plenty in the nomadic fleet.
                </p>
                <p className="card-text text-justify">
                    Few Alamarsie have carried through the centuries their absolute allegiance to the cult and their fanatical belief in Arthur's teachings. They banded together in a sect called the Cult of the Black Sun. Its followers absolutely deny any other teachings, limit contact with the outside world (even with other Alamarsie on their hiveship) and periodically perform strange rituals, including tattooing in unknown languages, reciting Arthur's manuscripts and making pilgrimages to the <TextInlineLink name={"bs_star_hedwig"} text={"Hedwig"} /> system.
                </p>
                <p className="card-text text-justify">
                    It was there that Arthur's journey ended: he disappeared under mysterious circumstances while attempting to explore space around that star. If the records are to be believed, his ship disappeared from radar and was later found without a pilot. No trace of a struggle or space battle could be found: either Arthur fled on his own, frightened by the influx of fame, or his enemies framed the attempt as an escape from the nomadic fleet.
                </p>
            </>
    }
}
